import { Grid ,Box,Typography, ThemeProvider} from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import useWindowSize from "../../utils/useWindowResize";
import { Link } from "react-router-dom";
import { black, blue, red, theme, white } from "../../styles/theme";
import { caseStudyExpand, caseStudyExpanded, menuSX, menuSXBlue } from "../../styles/styles";
import { Title } from "../../components/utils/utils";
import { CaseStudy, caseStudy } from "../../components/caseStudy";


export default function SafeAndSocialPage(props) {

  return (
      <>

    <ThemeProvider theme={theme}>
      <Grid container item sx={{
        display:'flex',
        bgcolor:'secondary.bg',
        transition:'all ease .1s',
        // height:'200vh',
        position:'relative',
        alignContent:'flex-start',
      }}>
        <Grid container item xs={12} 
          sx={{
            display:'flex',
            position:'sticky',
            top:0,
            mixBlendMode:'difference'
            }}>
            <Grid container item xs={12} md>
            <Box>
              <Box component='span' sx={menuSX('#000')}>
                <Title title ={<>George&nbsp;Junichi McKibbon&nbsp;Macrae</>} 
                      color1={white} colorHover={red} link={'/'}/>
              </Box>
            </Box>  
            </Grid>
            <Grid container item xs={12} md sx={{justifyContent:{xs:'start',md:'start'}}}>
              <Box>
              <Title title ={<>Work</>} color1={white} colorHover={red} link={'/work'}/>
              <Title title ={<>Info</>} color1={white} colorHover={red} link={'/info'}/>
              </Box>
            </Grid>
        </Grid>

        <Grid xs={0} md={1}/>
        <Grid xs={0} md={10}>
        
          <Box sx={{pt:5}}>
          <CaseStudy 
            Title={"SAFE & SOCIAL"} 
            Date={"2020"} 
            Subtitle={"Emily Carr University Art Direction Project"}
            Body={"A hypothetical guerilla campaign aimed to bolster a sense of community and reinforce pandemic safe behaviour in a time of social isolation.  This campaign aimed to normalize a new space of socialization, provide positive feedback for safe choices, and provided community-building affirmations. This campaign was designed in collaboration with Tammo Fouad."}
            Tags={["Branding", "Communication Design", "Art Direction"]}
            Img={["/images/Safe&Social/bus-ad-side.png","/images/Safe&Social/CityPosterMockup.png"]}
            Overview={"As the pandemic began a new era of isolation, a lot of people started to feel separated from their friends and longed for a sense community.  Our campaign wanted to provide a sense of community and coming together for people who were making good choices by staying apart."}
            Proccess={"We wanted to create a feel-good and approachable brand identity that made our target audience feel warm and optimistic.  Our message was to encourage social behaviour but in a safe way.  Through a barrage of assets, such as stickers, social media posts and posters, we hoped to foster a community of people who felt like they were part of something bigger by making safe decisions in the pandemic."}
            // Wireframes={["/images/division/wireframe2.png","/images/division/wireframe1.png"]}
            ImagesCol1={["images/Safe&Social/can 2.png","images/Safe&Social/coffee.png","images/Safe&Social/instagramAd.png","images/Safe&Social/phone.png"]}
            ImagesCol2={["images/Safe&Social/01 (1).png","images/Safe&Social/Outdoor-Ad-Screen-MockUp_3pet.png","images/Safe&Social/Outdoor-Street-Poster-Mockup (1).png","images/Safe&Social/sticker1.png","images/Safe&Social/sticker2.png"]}
            />
        </Box>

        
        </Grid>
        <Grid xs={0} md={1}/>

      </Grid>
      </ThemeProvider>
    
    
      </>
  );
}


import React, { useEffect, useState } from 'react';
import { ReactP5Wrapper } from "react-p5-wrapper";
import { Box, Checkbox, Grid, Slider, Switch, Typography } from "@mui/material";

let noLoop = true;

function sketch(p){
    let cW =500;
    let cH = 500;
    let pg;
    let boxX,boxY,boxW,boxH;
    var vehicles = [];
    var selectedPoints =[];
    var numberOfMovingPoints = 60;
    var cols = 65;
    var tileD;
    var rows;

    let bgColor = 'rgb(0,0,0)';


    let leftAlign = false;
    p.setup = () =>{
        p.createCanvas(cW, cH);
        setupImg(p);
    } 
    p.draw = (props) => {
        if( noLoop){
            return p.noLoop();
        }
        p.background(bgColor);
        
        if(p.frameCount % 180 === 0){
            for (var i=0;i<numberOfMovingPoints;i++){
                var vehicle = vehicles[p.floor(p.random(vehicles.length))];
                var xIndex = p.floor(p.random(3));
                var yIndex = p.floor(p.random(3));
                
                var xx = 0; 
                var yy = 0;
                if(xIndex === 0){
                    xx = -1;
                }
                else if(xIndex === 1){
                    xx= 0;
                }
                else{
                    xx=1;
                }

                if(yIndex === 0){
                    yy = -1;
                }
                else if(yIndex === 1){
                    yy= 0;
                }
                else{
                    yy=1;
                }
                vehicle.target.x = vehicle.target.x + xx*tileD;
                vehicle.target.y = vehicle.target.y + yy*tileD;

            }
        }
        for(var i = 0; i < vehicles.length; i++){
            var v = vehicles[i];
            v.behaviors();
            v.update();
            v.show();
        }
    //   p.normalMaterial();
    //   p.push();
    //   p.rotateZ(p.frameCount * 0.01);
    //   p.rotateX(p.frameCount * 0.01);
    //   p.rotateY(p.frameCount * 0.01);
    //   p.plane(100);
    //   p.pop();
    };
    const setupImg = (p) =>{
        pg = p.createGraphics(cW,cH);
        
        pg.push();
        // console.log(cW);
        if(leftAlign){
            pg.translate(0,p.height );
        }else{
            pg.translate(cW/4,p.height );

        }
        
        pg.textFont('Helvetica');
        pg.textSize(cW/3);
        pg.textAlign(p.LEFT,p.BASELINE);
        pg.text('hello ',0,0);
        // pg.fill(15,102,154);
        pg.pop();
        p.image(pg,0,0,cW,cH);

        tileD = cW/cols;
        rows = cH/tileD;
        vehicles = [];
        for(var x =0; x<cols ; x++){
            for(var y = 0; y < rows ; y++){
                var sx = x*tileD ;
                var sy = y*tileD ;
                p.push();
                p.noFill();
                p.ellipse(x*tileD+tileD/2,y*tileD+tileD/2,tileD,tileD);
                p.pop();
                for (var i = 0 ; i<4 ; i++){
                    if(i===0){
                        sx = x*tileD ;
                        sy = y*tileD ;
                    }
                    else if(i === 1){
                        sx = x*tileD + tileD;
                        sy = y*tileD ;
                    }
                    else if(i === 2){
                        sx = x*tileD ;
                        sy = y*tileD + tileD;
                    }
                    else if(i === 3){
                        sx = x*tileD + tileD;
                        sy = y*tileD + tileD;
                    }
                    var r = pg.get(sx,sy)[0];
                    var g = pg.get(sx,sy)[1];
                    var b = pg.get(sx,sy)[2];
                    var a = pg.get(sx,sy)[3];
                    
                        
                    if(r===0 && g===0 && b===0 && a===255){
                        selectedPoints.push([x,y]);
                        var vehicle = new Vehicle(p,x*tileD + tileD/2,y*tileD + tileD/2,tileD);
                        vehicles.push(vehicle);
                        break;
                    }
                }
                
            }
        }

    }
    const setupBox = p =>{

    }

    
    p.updateWithProps = props =>{
        try{
            if(cW !== props.containerDim.w){
                cW=props.containerDim.w;
                p.resizeCanvas(cW,cH);
                leftAlign = props.left;
                setupImg(p);
            
            }
            if(cH !== props.containerDim.h){
                cH=props.containerDim.h;
                p.resizeCanvas(cW,cH);
                leftAlign = props.left;
                setupImg(p);
            }

            // console.log(bgColor,props.bgColor,bgColor === props.bgColor);
            // if(bgColor !== props.bgColor){
            //     bgColor = props.bgColor;
            // }
            
            // if(props.bgColor.r !==r || props.bgColor.g !==g || props.bgColor.b !==b  || props.bgColor.a !== a){
            //     r =props.bgColor.r ;
            //     g =props.bgColor.g ;
            //     b =props.bgColor.b ;
            //     a =props.bgColor.a ;
            //     bgColor = 'rgba('+r+','+g+','+b+','+a+')';
            // }



        }catch(e){}
        
        // pg.textFont('Helvetica');
        // pg.push();
        // // pg.translate(p.width/2,p.height/2 );
        // pg.textAlign(p.CENTER,p.CENTER);
        // pg.textSize(cW/2+250);
        // pg.fill(100);
        // pg.background(200);
        // pg.text('a',0,0);
        // pg.pop();

        // p.image(pg,0,0,cW,cH);
    
    }
}

class Vehicle {
    constructor(p, x, y, tileD) {
        this.p = p;
        this.pos = p.createVector(x, y);
        this.target = p.createVector(x, y);
        this.ogX = x;
        this.ogY = y;
        
        this.vel = p.constructor.Vector.random2D();
        this.acc = p.createVector();
        this.r = 4;
        this.maxspeed = 8;
        this.maxforce = 2;
        this.tileD = tileD;
        // console.log('hi')
        // console.log(p.constructor.Vector.random2D());
    }
    update() {
        this.pos.add(this.vel);
        this.vel.add(this.acc);
        this.acc.mult(0);

    }
    show() {
        this.p.stroke(0,0,255);
        this.p.strokeWeight(this.tileD);
        this.p.point(this.pos.x, this.pos.y);
    }
    behaviors(){
        var arrive = this.arrive(this.target);
        this.applyForce(arrive);

        var mouse = this.p.createVector(this.p.mouseX,this.p.mouseY);
        var flee = this.flee(mouse);

        arrive.mult(1);
        flee.mult(5);
        this.applyForce(arrive);
        this.applyForce(flee);
    }
    applyForce(f){
        this.acc.add(f);
    }
    seek(target){
        var desired = this.p.constructor.Vector.sub(target,this.pos);
        desired.setMag(this.maxspeed);
        var steer = this.p.constructor.Vector.sub(desired,this.vel);
        steer.limit(this.maxforce);
        return steer;
    }
    flee(target){
        var desired = this.p.constructor.Vector.sub(target,this.pos);
        var d = desired.mag();
        
        if(d<50){
            this.target.x = this.ogX;
            this.target.y = this.ogY;
            // console.log('mouse',this.target,this.ogTarget);

            desired.setMag(this.maxspeed);
            desired.mult(-1);
            var steer = this.p.constructor.Vector.sub(desired,this.vel);
            steer.limit(this.maxforce);
            return steer;
        }else return this.p.createVector(0,0);
    }
    arrive(target){
        var desired = this.p.constructor.Vector.sub(target,this.pos);
        var d = desired.mag();
        var speed = this.maxspeed;
        if(d < 100){
            speed = this.p.map(d,0,100,0,this.maxspeed);
        }else{
            // speed = 
        }
        desired.setMag(speed);
        var steer = this.p.constructor.Vector.sub(desired,this.vel);
        steer.limit(this.maxforce);
        return steer;
    }
}
export default function TypeSketch(props) {

    useEffect(() => {
        noLoop = false;
        return () => {
          noLoop = true;
        };
      }, []);
    const [containerDim, setContainerDim] = useState(null);
    const [leftAlign, setLeftAlign] = useState(false);

    useEffect(()=>{
        try{
            setContainerDim({w:props.container.getBoundingClientRect().width, h:props.container.getBoundingClientRect().height});
            // console.log(props.container.getBoundingClientRect().x);
            if(props.container.getBoundingClientRect().x < 100){
                setLeftAlign(true);
            }else{
                setLeftAlign(false);
            }
            // console.log(props.container.getBoundingClientRect());
        }catch(e){}
    },[props]);
    return(
        <>
        <Box position='relative'>
            <ReactP5Wrapper sketch={sketch} containerDim={containerDim} bgColor={props.bgColor} left={leftAlign}/>
        </Box>
        </>
    )
}

import { Grid ,Box,Typography, ThemeProvider} from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import useWindowSize from "../../utils/useWindowResize";
import { Link } from "react-router-dom";
import { black, blue, red, theme, white } from "../../styles/theme";
import { caseStudyExpand, caseStudyExpanded, menuSX, menuSXBlue } from "../../styles/styles";
import { Title } from "../../components/utils/utils";
import { CaseStudy, caseStudy } from "../../components/caseStudy";


export default function DivisionPage(props) {

  return (
      <>

    <ThemeProvider theme={theme}>
      <Grid container item sx={{
        display:'flex',
        bgcolor:'secondary.bg',
        transition:'all ease .1s',
        // height:'200vh',
        position:'relative',
        alignContent:'flex-start',
      }}>
        <Grid container item xs={12} 
          sx={{
            display:'flex',
            position:'sticky',
            top:0,
            mixBlendMode:'difference'
            }}>
            <Grid container item xs={12} md>
            <Box>
              <Box component='span' sx={menuSX('#000')}>
                <Title title ={<>George&nbsp;Junichi McKibbon&nbsp;Macrae</>} 
                      color1={white} colorHover={red} link={'/'}/>
              </Box>
            </Box>  
            </Grid>
            <Grid container item xs={12} md sx={{justifyContent:{xs:'start',md:'start'}}}>
              <Box>
              <Title title ={<>Work</>} color1={white} colorHover={red} link={'/work'}/>
              <Title title ={<>Info</>} color1={white} colorHover={red} link={'/info'}/>
              </Box>
            </Grid>
        </Grid>

        <Grid xs={0} md={1}/>
        <Grid xs={0} md={10}>
        
          <Box sx={{pt:5}}>
            <CaseStudy 
              Title={"DESIGN FOR START-UPS x DIVISION"} 
              Date={"2021"} 
              Subtitle={<>Product Design for Division’s lease-management app through<Box component='span' mx={1} sx={menuSX(red)}>
              <Box component='a' style={{ textDecoration: 'none' }}
                  sx={{ color:'primary.linkColor',
                  '&:hover':{
                    color:'primary.linkHover',
                  }}}
              target="_blank"
              href="https://shumka.ecuad.ca/program/design-for-startups/">the&nbsp;Shumka&nbsp;Center</Box>
            </Box></>}
              Body={"Design for Startups builds and strengthens the local design and technology ecosystem by connecting designers to early stage technology companies in order to develop applied design solutions to company problems. The program is operated by the Shumka Centre at Emily Carr University of Art + Design, which fosters the movement of artists and designers into systems and situations where their work and ideas can have the most impact. With mentorship from program leads I worked with the start-up Division, a company aimed to build a long-term rental real-estate management application that uses real-time data and other industry metrics to optimize rental rates and maximize profits. Through UX/UI design proccess, I focused on understanding the goals, the users, and the data to then design appealing dashboards and a navigation system for Division’s M.V.P."}
              Tags={["Branding", "UI/UX", "Research"]}
              Img={["/images/division/DashboardMockup1.png","/images/division/ProjectListMockup1.png"]}
              Overview={"Division is a lease management tool that utilizes real-time market data, real-estate metrics and financial goals to optimize long-term rental prices. Up until Division, lease management has not been optimized, resulting in the loss of huge profit potential. Using the available market data and other professional rental metrics, the Division app will not only take the guesswork out of unit pricing, but will also simplify lease management into one easy-to-use and efficient application."}
              Proccess={<>We started by establishing users profiles of the lease managers who would use the Division app. We found that people can find real estate can be intimidating, dry and complicated. With this information, we knew we had to focus on creating an app that would hide the complicated analytical aspects of the industry in the back-end while delivering the important information through an appealing and intuitive front-end.<p>We continued our research by analyzing other platforms through SWOT analysis. This gave us insight into branding techniques, UI components, User flow and how to large amounts of data.</p><p>We were then able to create wireframes, establish the application hierarchy and then finally move into high fidelity layouts</p></>}
              Type={"/images/division/type.svg"}
              Button={"/images/division/buttons.svg"}
              Colours={"/images/division/colours.svg"}
              Wireframes={["/images/division/wireframe2.png","/images/division/wireframe1.png"]}
              ImagesCol1={["/images/division/Projects.png","/images/division/ProjectsList.png","/images/division/units.png","/images/division/market.png"]}
              ImagesCol2={["/images/division/Leasing.png","/images/division/market2.png"]}
              />
          </Box>

        
        </Grid>
        <Grid xs={0} md={1}/>

      </Grid>
      </ThemeProvider>
    
    
      </>
  );
}


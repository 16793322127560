import { Hidden } from "@mui/material";

export const menuSX = (color) =>{
    return({
        cursor:'pointer',
        '&:hover':{
            // borderBottom:"2px solid "+color,
            color: color,
            textDecorationColor: color,
            fontFamily:['crayonette-djr','DM Sans','neue-haas-grotesk-display','sans-serif'].join(','),

          }
    })
  }

  export const caseStudyExpand ={
      height:'1rem',
      overflow:'hidden',
      transition:'all ease .5s',
      opacity:1,
  }

  export const caseStudyExpanded = h =>{
      return({
        height:h,
        overflow:'hidden',
        transition:'all ease .5s',
        opacity:1,

      });
    }
  
    export const workMenuItem = content =>{
      return(
      {
        overflow:'hidden',
        width:'100%', 
        display:'flex', 
        alignContent:'start',
        justifyContent:'center',
        transition:'all .5s ease',
        display:'flex',
        position:'relative',
        '&:hover':{
          borderRadius:'100px',
          
        },
        '&:after':{
          content:'""',
          position:'absolute',
          left:0,
          top:0,
          width:'100%',
          height:'100%',
          bgcolor:'black',
          mixBlendMode:'hue',
          transition:'all .5s ease',
          opacity:0,

        },
        '&:hover:after':{
          opacity:1,

        },
        '&:before':{
          content:`"${content}"`,
          position:'absolute',
          display:'flex', 
          // top:'50%',
          alignItems:'center',
          textAlign:'center',
          px:5,
          transition:'all .5s ease',
          display:'flex',
          opacity:0,
          // width:'100%',
          height:'100%',
          zIndex:10,
        },

      '&:hover:before':{
          opacity:1,
          // mixBlendMode:'none',
          bgcolor:'rgba(255,255,255,.75)'
          
          
        },
      }
      )
    }
import { createTheme } from '@mui/material/styles';

export const white = "#FFF";
export const black ='#000';
export const blue = '#415FFC';
export const red = '#FF0000';
export const theme = createTheme({
    palette:{
		primary:{
            main:white,
            bg:black,
            linkColor:blue,
            linkHover:red,
        },
        secondary:{
            main:black,
            bg:white,
            linkColor:blue,
            linkHover:red,
        }
	},

});


theme.typography.H1 = {
    fontFamily:['DM Sans','neue-haas-grotesk-display','sans-serif'].join(','),
	fontWeight:600,
	fontStyle:'normal',


    [theme.breakpoints.down('sm')]:{
		fontSize:'2.5rem',
		// lineHeight:'0rem',
		// letterSpacing:'.1rem',
	},
	
	[theme.breakpoints.up('sm')]:{
		fontSize:'2.5rem',
		// lineHeight:'1rem',

		// letterSpacing:'.1rem',
	},
	[theme.breakpoints.up('md')]:{
		fontSize:'1.75rem',
		// lineHeight:'2rem',

		// letterSpacing:'.1rem',
	},
	[theme.breakpoints.up('lg')]:{
		fontSize:'2rem',
		// lineHeight:'6rem',
		// letterSpacing:'.1rem',
	}
}
theme.typography.H2 = {
    fontFamily:['DM Sans','neue-haas-grotesk-display','sans-serif'].join(','),
	fontWeight:400,
	fontStyle:'normal',


    [theme.breakpoints.down('sm')]:{
		fontSize:'1.75rem',
		// lineHeight:'0rem',
		// letterSpacing:'.1rem',
	},
	
	[theme.breakpoints.up('sm')]:{
		fontSize:'2.5rem',
		// lineHeight:'1rem',

		// letterSpacing:'.1rem',
	},
	[theme.breakpoints.up('md')]:{
		fontSize:'1.75rem',
		// lineHeight:'2rem',

		// letterSpacing:'.1rem',
	},
	[theme.breakpoints.up('lg')]:{
		fontSize:'2rem',
		// lineHeight:'6rem',
		// letterSpacing:'.1rem',
	}
}
theme.typography.H3 = {
    fontFamily:['DM Sans','neue-haas-grotesk-display','sans-serif'].join(','),
	fontWeight:300,


    [theme.breakpoints.down('sm')]:{
		fontSize:'1rem',
		// lineHeight:'0rem',
		// letterSpacing:'.1rem',
	},
	
	[theme.breakpoints.up('sm')]:{
		fontSize:'1rem',
		// lineHeight:'1rem',

		// letterSpacing:'.1rem',
	},
	[theme.breakpoints.up('md')]:{
		fontSize:'1rem',
		// lineHeight:'2rem',

		// letterSpacing:'.1rem',
	},
	[theme.breakpoints.up('lg')]:{
		fontSize:'1rem',
		// lineHeight:'6rem',
		// letterSpacing:'.1rem',
	}
}
theme.typography.H4 = {
    fontFamily:['DM Sans','neue-haas-grotesk-display','sans-serif'].join(','),
	fontWeight:800,
	fontStyle:'italic',
	textDecoration:'underline',


    [theme.breakpoints.down('sm')]:{
		fontSize:'1rem',
		// lineHeight:'0rem',
		// letterSpacing:'.1rem',
	},
	
	[theme.breakpoints.up('sm')]:{
		fontSize:'1rem',
		// lineHeight:'1rem',

		// letterSpacing:'.1rem',
	},
	[theme.breakpoints.up('md')]:{
		fontSize:'2rem',
		// lineHeight:'2rem',

		// letterSpacing:'.1rem',
	},
	[theme.breakpoints.up('lg')]:{
		fontSize:'2.5rem',
		// lineHeight:'6rem',
		// letterSpacing:'.1rem',
	}
}
theme.typography.H5 = {
    fontFamily:['DM Sans','neue-haas-grotesk-display','sans-serif'].join(','),
	fontWeight:800,

    [theme.breakpoints.down('sm')]:{
		fontSize:'1rem',
		// lineHeight:'0rem',
		// letterSpacing:'.1rem',
	},
	
	[theme.breakpoints.up('sm')]:{
		fontSize:'1.2rem',
		// lineHeight:'1rem',

		// letterSpacing:'.1rem',
	},
	[theme.breakpoints.up('md')]:{
		fontSize:'1.5rem',
		// lineHeight:'2rem',

		// letterSpacing:'.1rem',
	},
	[theme.breakpoints.up('lg')]:{
		fontSize:'2rem',
		// lineHeight:'6rem',
		// letterSpacing:'.1rem',
	}
}
theme.typography.Body1 = {
    fontFamily:['DM Sans','neue-haas-grotesk-display','sans-serif'].join(','),
	fontWeight:500,
	fontStyle:'normal',


    [theme.breakpoints.down('sm')]:{
		fontSize:'1.1rem',
		// lineHeight:'0rem',
		// letterSpacing:'.1rem',
	},
	
	[theme.breakpoints.up('sm')]:{
		fontSize:'1.1rem',
		// lineHeight:'1rem',

		// letterSpacing:'.1rem',
	},
	[theme.breakpoints.up('md')]:{
		fontSize:'1.1rem',
		// lineHeight:'2rem',

		// letterSpacing:'.1rem',
	},
	[theme.breakpoints.up('lg')]:{
		fontSize:'1.2rem',
		// lineHeight:'6rem',
		// letterSpacing:'.1rem',
	}
}
theme.typography.Body2 = {
    fontFamily:['DM Sans','neue-haas-grotesk-display','sans-serif'].join(','),
	fontWeight:400,
	fontStyle:'normal',

}
import { Grid ,Box,Typography, ThemeProvider} from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { menuSX } from "../styles/styles";
import { red, theme, white } from "../styles/theme";
import useWindowSize from "../utils/useWindowResize";
import TypeSketch from "./typeSketch";
import { Title } from "./utils/utils";


export default function Landing(props) {
  const sketchContainerRef = createRef();
  const [sketchContainer,setSketchContainer]=useState(null);


  const size = useWindowSize();

  useEffect(()=>{
    setSketchContainer(sketchContainerRef.current);
  },[size])
  


  return (
      <>    
      <ThemeProvider theme={theme}>

        <Grid container item sx={{
          bgcolor:'primary.bg',
          height:'100vh',
          transition:'all ease .1s',
          overflow:'hidden'
        }}>
          <Grid container item xs={12}>
            <Grid container item xs={12} md>
            <Box>
              <Title title ={<>George&nbsp;Junichi McKibbon&nbsp;Macrae</>} 
                      color1={white} colorHover={red} link={'/'}/>
            </Box>
            </Grid>
            <Grid container item xs={12} md sx={{justifyContent:{xs:'start',md:'start'}}}>
              <Box>
       
              <Title title ={<>Work</>} color1={white} colorHover={red} link={'/work'}/>
              <Title title ={<>Info</>} color1={white} colorHover={red} link={'/info'}/>

              </Box>
            </Grid>
          </Grid>

          <Grid container item xs={12} sx={{display:'flex', alignItems:'end'}}>
            <Grid container item xs>

              <Typography variant = 'Body1' sx={{color:'primary.main'}}>
                <Box  component='span' > 
                  currently : designer & developer @&nbsp;
                  
                    <Box  component='a' style={{ textDecoration: 'none' }}
                          href="https://www.liiift.studio/"
                          target="_blank"
                          sx={{ color:'primary.linkColor',
                                '&:hover':{
                                  color:'primary.linkHover',
                                  fontFamily:['crayonette-djr','DM Sans','neue-haas-grotesk-display','sans-serif'].join(','),
                                  zIndex:100,
                                }}}>
                      Liiift&nbsp;Studio
                    </Box>
                  
                </Box>
                </Typography>
              </Grid>
            <Grid container item xs={12} md={8} sx={{ justifyContent:{xs:'start',md:'end'}}}>
              <Box ref ={sketchContainerRef} 
              component='a'
              href="mailto:grgmacrae@gmail.com"
              target="_blank"
              sx={{
                display:'flex',
                width:'100%',
                height:'40vh',
                alignItems:'end',
                cursor:'crosshair'
                }}>

                  <TypeSketch container={sketchContainer}/>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        </ThemeProvider>

      </>
  );
}


import { Box, Grid, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { caseStudyExpand, caseStudyExpanded, menuSX } from "../styles/styles";
import { blue, red } from "../styles/theme";
import { CSExpandedBody } from "./CSExpandedBody";

const colPad = 4;
export const CaseStudy = ({Title,Date,Subtitle,Body,Tags,Img,Overview,Proccess,Type,Button,Colours,Wireframes,ImagesCol1,ImagesCol2,Link}) =>{

    const [expandedBody,setExpandedBody] = useState(<></>);
    
    useEffect(()=>{
      try{
        if (Overview !== undefined ){
          setExpandedBody(<CSExpandedBody Overview={Overview} Proccess={Proccess} Wireframes={Wireframes} Type={Type} Button={Button} Colours={Colours} ImagesCol1={ImagesCol1} ImagesCol2={ImagesCol2} />)
        } else if(Link!== undefined){
          setExpandedBody(
            <Grid container item xs={12} sx={{}}>
            <Typography variant = 'H1' sx={{color:'secondary.linkColor'}}>
              <Box component='span' mx={1} sx={menuSX(red)}>
              <Box component='a' style={{ textDecoration: 'none' }}
                    sx={{ color:'primary.linkColor',
                    '&:hover':{
                      color:'primary.linkHover',
                    }}}
                target="_blank"
                href={Link[1]}>{Link[0]}</Box>
              </Box>
              </Typography>
          </Grid>

          )
        }
      }
      catch(e){}
    },[]);

    return (
        <>
        <Grid container item xs={12} 
          sx={{
            display:'flex',
            alignContent:'start',
            }}>
          <Grid container item xs={12} sx={{alignItems:'baseline'}}>
          <Grid container item xs={12} md={6}>
            <Typography variant = 'H1'>
                {Title}
              </Typography>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Typography variant = 'H3'>
                {Date}
              </Typography>
          </Grid>

          </Grid>
          <Grid container item xs={12} md={6}>
            <Box>
            <Box component='p'>

            </Box>
            <Box component='p'>
              <Typography variant = 'H3'>
                {Subtitle}
              </Typography>
            </Box>
            <Box component='p' sx={{pr:colPad}}>
              <Typography variant = 'Body1'>
               {Body}
              </Typography>
            </Box>
            </Box>
          </Grid> 
          <Grid container item xs={12}md={6} pb={20}>
            <Typography variant = 'Body1'>
            <Box sx={{display:'flex',flexDirection:{xs:'row',md:'column'} ,pb:{xs:2,md:0}}}>
                {Tags.map(function(element,i){
                    return (
                    <Box>
                    {element}
                    </Box>)
                })}
            </Box>
              </Typography>
          </Grid>

          {Img.map(function(element,i){
              return (
                <Grid container item xs={Img.length ===1 ? 12:6} sx={{pr:colPad,alignContent:'center'}}>
                <Box sx={{width:'100%',pb:2,alignContent:'start'}}>
                  <img src={element} style={{maxWidth:'100%'}} key={i}/>           
                </Box>
                </Grid> 
              )
          })}

          {expandedBody}

        </Grid>
        </>
    )
}
import { Grid ,Box,Typography, ThemeProvider} from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import useWindowSize from "../../utils/useWindowResize";
import { Link } from "react-router-dom";
import { black, blue, red, theme, white } from "../../styles/theme";
import { caseStudyExpand, caseStudyExpanded, menuSX, menuSXBlue } from "../../styles/styles";
import { Title } from "../../components/utils/utils";
import { CaseStudy, caseStudy } from "../../components/caseStudy";


export default function FiveCornersPage(props) {

  return (
      <>

    <ThemeProvider theme={theme}>
      <Grid container item sx={{
        display:'flex',
        bgcolor:'secondary.bg',
        transition:'all ease .1s',
        // height:'200vh',
        position:'relative',
        alignContent:'flex-start',
      }}>
        <Grid container item xs={12} 
          sx={{
            display:'flex',
            position:'sticky',
            top:0,
            mixBlendMode:'difference'
            }}>
            <Grid container item xs={12} md>
            <Box>
              <Box component='span' sx={menuSX('#000')}>
                <Title title ={<>George&nbsp;Junichi McKibbon&nbsp;Macrae</>} 
                      color1={white} colorHover={red} link={'/'}/>
              </Box>
            </Box>  
            </Grid>
            <Grid container item xs={12} md sx={{justifyContent:{xs:'start',md:'start'}}}>
              <Box>
              <Title title ={<>Work</>} color1={white} colorHover={red} link={'/work'}/>
              <Title title ={<>Info</>} color1={white} colorHover={red} link={'/info'}/>
              </Box>
            </Grid>
        </Grid>

        <Grid xs={0} md={1}/>
        <Grid xs={0} md={10}>
        
          <Box sx={{pt:5}}>
          <CaseStudy 
            Title={"5 CORNERS CAFE"} 
            Date={"2021"} 
            Subtitle={"Rebranding for White Rock BC's oldest resteraunt."}
            Body={"Five Corners Cafe is White Rock BC's oldest resteraunt. An eclectic 50's diner needed a little bit of updating without sacraficing their unique brand. I worked with the client to redesign their menu, as well as create graphics for merchandise, buisness cards and promotions!"}
            Tags={["Branding", "Graphic Design", "Product Design"]}
            Img={["/images/5corners/posterOrange.png","/images/5corners/bidnessCard22.png"]}
            Overview={"I worked with the client to establish what was working and what wasn't.  We decided to capitalize on different policies in their branding, such as ‘cash only’, and to keep some of their iconic slogans, ‘GOOD FOOD’.  They had a specific idea of the colours they wanted to use and the era they wanted to emulate.  The challenges that we focused on were a menu that was very basic, cluttered and unprofessional and inconsistent branding among different assets, such as signage, merchandise and business cards"}
            Proccess={"Knowing what the client wanted, the I worked to create a brand identity that used some typefaces that were present on their signs that they liked.  I extended the colours and typefaces and considered the feeling of the restaurant as well as the clientele to establish a brand identity.  I then was able to design a legible and easy-to-navigate menu that would be easy to edit and print.  Finally, I designed a simple business card and promotional graphics that have been used on hats and shirts!"}
            Type={"/images/5corners/type.svg"}
            Colours={"/images/5corners/colours.svg"}
            ImagesCol1={["/images/5corners/5Corners-04-04.png","/images/5corners/menu1Mockup.png","/images/5corners/hat.png"]}
            ImagesCol2={["/images/5corners/bidnessMockup.png","/images/5corners/menu2Mockup.png","/images/5corners/bidnessCard23.png"]}
            />
        </Box>

        
        </Grid>
        <Grid xs={0} md={1}/>

      </Grid>
      </ThemeProvider>
    
    
      </>
  );
}


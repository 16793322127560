import { Grid ,Box,Typography, ThemeProvider} from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import useWindowSize from "../utils/useWindowResize";
import { Link } from "react-router-dom";
import { black, blue, red, theme, white } from "../styles/theme";
import { caseStudyExpand, caseStudyExpanded, menuSX, menuSXBlue, workMenuItem, workMenuItemHoverDescription } from "../styles/styles";
import { Title } from "../components/utils/utils";
import { CaseStudy, caseStudy } from "../components/caseStudy";


export default function WorkPage2({Projects}) {

  return (
      <>
    <ThemeProvider theme={theme}>
      <Grid container item sx={{
        display:'flex',
        bgcolor:'secondary.bg',
        transition:'all ease .1s',
        // height:'200vh',
        position:'relative',
        alignContent:'flex-start',
      }}>
        <Grid container item xs={12} 
          sx={{
            display:'flex',
            position:'sticky',
            top:0,
            mixBlendMode:'difference',
            zIndex:10,
            }}>
            <Grid container item xs={12} md>
            <Box>
              <Box component='span' sx={menuSX('#000')}>
                <Title title ={<>George&nbsp;Junichi McKibbon&nbsp;Macrae</>} 
                      color1={white} colorHover={red} link={'/'}/>
              </Box>
            </Box>  
            </Grid>
            <Grid container item xs={12} md sx={{justifyContent:{xs:'start',md:'start'}}}>
              <Box>
                <Title title ={<>Info</>} color1={white} colorHover={red} link={'/info'}/>
              </Box>
            </Grid>
        </Grid>

        
        <Grid item xs={0} md={1}/>
        <Grid container item  xs={0} md={10}>
            <Grid container item xs={12} sx={{pt:5}}>
                <Grid container item xs={12} sx={{pt:5}}>
                    <Typography variant="Body2">
                    Last updated March 2022
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{pt:5}}>
          <Typography variant="Body2">
          
          <Box component='span'>
          Ask me about my current work @
          </Box> 
          <Box component='span' mx={1} sx={menuSX(red)}>
              <Box component='a' style={{ textDecoration: 'none' }}
                  sx={{ color:'primary.linkColor',
                  '&:hover':{
                    color:'primary.linkHover',
                  }}}
              target="_blank"
               href="https://www.liiift.studio/">Liiift&nbsp;Studio</Box>
            </Box>!
          </Typography>
        </Grid>
                

                <Grid container item sx={{py:5}}>
                    {Projects.map(function(proj,i){
                        return (
                            <Grid container item xs={6} md={4} sx={{pr:2,pb:2,}} key={i}>
                                <Link to={proj.Link} style={{textDecoration:'none',}} key={i}>                        
                                    <Typography variant="H5" color='primary.linkColor'>

                                    <Box sx={workMenuItem(proj.Summary)} key={i}>
                                        <img src={proj.CoverImg} style={{maxWidth:'100%'}} key={i} />
                                    </Box>
                                    </Typography> 
                                </Link>
                            </Grid>        

                    )})}
            </Grid>
        <Grid item xs={0} md={1}/>

        </Grid>

        </Grid>

      </Grid>
      </ThemeProvider>
    
    
      </>
  );
}


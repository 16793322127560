import { Box, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { menuSX } from "../../styles/styles"
import { white } from "../../styles/theme"

export const Title = ({title,color1,colorHover,link}) =>{
    return (
    <>
        <Typography variant ='H1' sx={{color:colorHover}}>
        <Box component='span' mr={2} sx={menuSX(colorHover)}>
        <Link to={link} style={{
            textDecoration:'none',
            color:color1,
            '&:hover':{
                color:colorHover
            }}}>
            {title}
        </Link>
        </Box>
        </Typography>
    </>)
}
import { Grid ,Box,Typography, ThemeProvider} from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import useWindowSize from "../../utils/useWindowResize";
import { Link } from "react-router-dom";
import { black, blue, red, theme, white } from "../../styles/theme";
import { caseStudyExpand, caseStudyExpanded, menuSX, menuSXBlue } from "../../styles/styles";
import { Title } from "../../components/utils/utils";
import { CaseStudy, caseStudy } from "../../components/caseStudy";


export default function DABCPage(props) {

  return (
      <>

    <ThemeProvider theme={theme}>
      <Grid container item sx={{
        display:'flex',
        bgcolor:'secondary.bg',
        transition:'all ease .1s',
        // height:'200vh',
        position:'relative',
        alignContent:'flex-start',
      }}>
        <Grid container item xs={12} 
          sx={{
            display:'flex',
            position:'sticky',
            top:0,
            mixBlendMode:'difference'
            }}>
            <Grid container item xs={12} md>
            <Box>
              <Box component='span' sx={menuSX('#000')}>
                <Title title ={<>George&nbsp;Junichi McKibbon&nbsp;Macrae</>} 
                      color1={white} colorHover={red} link={'/'}/>
              </Box>
            </Box>  
            </Grid>
            <Grid container item xs={12} md sx={{justifyContent:{xs:'start',md:'start'}}}>
              <Box>
              <Title title ={<>Work</>} color1={white} colorHover={red} link={'/work'}/>
              <Title title ={<>Info</>} color1={white} colorHover={red} link={'/info'}/>
              </Box>
            </Grid>
        </Grid>

        <Grid xs={0} md={1}/>
        <Grid xs={0} md={10}>
        
          <Box sx={{pt:5}}>
          <CaseStudy 
            Title={"DABC History Wall"} 
            Date={"2020"} 
            Subtitle={
              <>Co-Design Project through Emily Carr University and with<Box component='span' mx={1} sx={menuSX(red)}>
                          <Box style={{ textDecoration: 'none' }}
                          component='a' 
                          target="_blank"
                          href="https://disabilityalliancebc.org/"
                          sx={{ color:'primary.linkColor',
                              '&:hover':{
                                color:'primary.linkHover',
                              }}}>
                               Disability&nbsp;Alliance&nbsp;BC</Box>
                        </Box></>}
            Body={"A project that explored the methodology of CoDesign to redesign the history wall in the new office of the Disability Alliance BC.  This project was in collaboration with Sara Lee, Jay Nho and Chloe Krause."}
            Tags={["Co-Design", "Branding", "Communication Design", "UX"]}
            Img={["/images/DABC/DABC_historywall.png"]}
            Overview={"This was a university group project. We were paired with a member of the DABC and then were tasked to create activities designed to enable us to learn about them and the organization.  Through several online sessions with our member, we established a rapport and learned about the values of the organization, stylistic preferences as well as concerns and priorities.  Our final product was a print-ready file that could be sent to a printer to create an easy to install viny mural for their new office."}
            Proccess={"Through four online sessions, we engaged with our member of the DABC through activities we designed to learn about what they liked about their old History Wall, their branding, and the essential information that needed to be displayed on their new wall.  We considered where the wall would be placed, highlighted programs and services, and allowed space for references where viewers could find more information."}
            Wireframes={["/images/DABC/oldWall.png","/images/DABC/hierarchy.png","/images/DABC/drafts.png"]}
            ImagesCol1={["/images/DABC/DABC_historywall.png"]}
            ImagesCol2={["/images/DABC/MockUp.png"]}
            />
        </Box>

        
        </Grid>
        <Grid xs={0} md={1}/>

      </Grid>
      </ThemeProvider>
    
    
      </>
  );
}


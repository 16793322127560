import { Grid ,Box,Typography} from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import Landing from "../components/landing";
import { Routes, Route, Link } from "react-router-dom";
import InfoPage from "./InfoPage";
import WorkPage from "./WorkPage";
import WorkPage2 from "./WorkPage2";
import DivisionPage from "./Projects/Division";
import CreativeCodingPage from "./Projects/CreativeCoding";
import FiveCornersPage from "./Projects/FiveCorners";
import DABCPage from "./Projects/DABC";
import SafeAndSocialPage from "./Projects/SafeAndSocial";
import IllustrationsPage from "./Projects/Illustrations";



const Projects = [
    {Link:"/division",
     CoverImg:"images/division/DashboardMockup1.png",
     Summary: "UI/UX Project developing the M.V.P for a real-estate leasing app."
    },

    {Link:"/creativeCoding",
     CoverImg:"images/CreativeCoding/creativeCoding.gif",
     Summary: "Capstone Project exploring Creative Coding and how it can be applied to design."

    },
    
    {Link:"/fiveCorners",
     CoverImg:"images/5corners/posterOrange.png",
     Summary: "Rebranding and Asset creation for a small buisness."

    },

    {Link:"/illustrations",
     CoverImg:"images/illustrations/youreOnCamera-1.png",
        Summary:"Collection of various Illustration work"
    },

    {Link:"/dabc",
     CoverImg:"images/DABC/MockUp.png",
        Summary:"Co-Design and Communication Design Project with the DABC."
    },

    {Link:"/SafeAndSocial",
    CoverImg:"images/Safe&Social/CityPosterMockup.png",
    Summary:"An Art direction project staging a hypothetical guerilla campaign."

   },
]

export default function Page(props) {




  return (
      <>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/info" element={<Info/>} />
        <Route path="/work" element={<Work/>} />
        <Route path="/division" element={<Division/>} />
        <Route path="/creativeCoding" element={<CreativeCoding/>} />
        <Route path="/fiveCorners" element={<FiveCorners/>} />
        <Route path ="/illustrations" element={<Illustrations/>} />
        <Route path="/dabc" element={<DABC/>} />
        <Route path="/safeAndSocial" element={<SafeAndSocial/>} />
      </Routes>

      </>
  );
}
function Home(){
    return(
        <Landing/>
    )
}
function Info(){
    return(
        <InfoPage/>
    )
}
function Work(){
    return(
        <WorkPage2 Projects={Projects}/>
    )
}

function Division(){
    return(
        <DivisionPage/>
    )
}
function FiveCorners(){
    return(
        <FiveCornersPage/>
    )
}
function CreativeCoding(){
    return(
        <CreativeCodingPage/>
    )
}
function Illustrations(){
    return(
        <IllustrationsPage/>
    )
}
function DABC(){
    return(
        <DABCPage/>
    )
}
function SafeAndSocial(){
    return(
        <SafeAndSocialPage/>
    )
}
import { Grid ,Box,Typography, ThemeProvider} from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import useWindowSize from "../utils/useWindowResize";
import { Link } from "react-router-dom";
import { blue, red, theme, white } from "../styles/theme";
import { menuSX, menuSXBlue } from "../styles/styles";
import { Title } from "../components/utils/utils";

export default function InfoPage(props) {
  return (
      <>
      <ThemeProvider theme={theme}>
      <Grid container item sx={{
        bgcolor:'primary.bg',
        height:'100vh',
        transition:'all ease .1s',
        overflow:'hidden',
      }}>
          <Grid container item xs md={6} sx={{display:'flex',alignContent:'flex-start'}}>
            <Box sx={{mixBlendMode:'difference'}}>

              <Title title ={<>George&nbsp;Junichi McKibbon&nbsp;Macrae</>} 
                      color1={white} colorHover={red} link={'/'}/>
            </Box>
            <Box sx={{pt:2,display:{xs:'none',md:'flex'},alignItems:'flex-start'}}>
              <Box component='img' src = "/images/profilePic.png"
              sx={{ width:'100%',objectFit:'contain',}}/>
            </Box>
          </Grid>

          <Grid container item xs={12} md={1} 
            sx={{ justifyContent:{xs:'start',md:'start'}}}>
            <Box>
            <Title title ={<>Work</>} color1={white} colorHover={red} link={'/work'}/>
            </Box>
          </Grid>

        <Grid container item xs={12} md>

          <Typography variant = 'H2' sx={{color:'primary.main'}}>
              George Macrae is a Designer and Front-end developer who currently works and lives in Vancouver, BC.  
            <Box component='p'>
              <Box component='span'>
                Right now, he is working at 
                <Box component='span' mx={1} sx={menuSX(red)}>
                  <Box component='a' style={{ textDecoration: 'none' }}
                  sx={{ color:'primary.linkColor',
                  '&:hover':{
                    color:'primary.linkHover',
                    //  fontFamily:['Press Start 2P', 'cursive'].join(','),
                    // fontStyle:'italic',
                  }}}
              target="_blank"
               href="https://www.liiift.studio/">Liiift&nbsp;Studio</Box>
            </Box>
                 writing code and moving things around in figma.
              </Box>
            </Box>
            <p>
            <Box sx={{pr:2}}>
            Want to chat?
            </Box>
            <Box component='span' mr={2} sx={menuSX(red)}>
              <Box component='a' style={{ textDecoration: 'none' }}
               sx={{ color:'primary.linkColor',
               '&:hover':{
                 color:'primary.linkHover',
                //  fontFamily:['Press Start 2P', 'cursive'].join(','),
                // fontStyle:'italic',

              }}}
              target="_blank"
               href="mailto:grgmacrae@gmail.com">Reach&nbsp;out.</Box>
            </Box>
            
            <Box component='span' mr={2} sx={menuSX(red)}>
              <Box component='a' target="_blank" style={{ textDecoration: 'none' }} 
               sx={{ color:'primary.linkColor',
               '&:hover':{
                 color:'primary.linkHover',
                //  fontFamily:['Press Start 2P', 'cursive'].join(','),
                //  fontStyle:'italic',
               }}}
               target="_blank"
               href="https://www.linkedin.com/in/george-macrae/">Link&nbsp;up.</Box>
            </Box>
            
            <Box component='span' mr={2} sx={menuSX(red)}>
              <Box component='a' target="_blank" style={{ textDecoration: 'none' }} 
                sx={{ color:'primary.linkColor',
                      '&:hover':{
                        color:'primary.linkHover',
                        // fontStyle:'italic',

                      }}}
                target="_blank"
                href="https://www.instagram.com/geomacrae/">DM&nbsp;me.</Box>
            </Box>
            </p>
            </Typography>

        </Grid>


      </Grid>
      </ThemeProvider>
    
      </>
  );
}


import { Box, Grid, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { caseStudyExpand, caseStudyExpanded, menuSX } from "../styles/styles";
import { blue, red } from "../styles/theme";

const colPad = 4;
export const CSExpandedBody = ({Overview,Proccess,Wireframes,Type,Button,Colours,ImagesCol1,ImagesCol2}) =>{
    const expandRef = useRef();
    const [expanded,setExpanded] = useState(false);
    const [expandedHeight,setExpandedHeight] = useState('2vh');
    const [expandedBody,setExpandedBody] = useState(<></>);
    const [wireframes, setWireframes] = useState(<></>);
    const [type, setType] = useState(<></>);
    const [buttons, setButtons] = useState(<></>);
    const [colours, setColours] = useState(<></>);

    
    useEffect(()=>{
      try{
        setExpandedHeight(expandRef.current.scrollHeight);
      }
      catch(e){}
    },[expanded]);


    useEffect(()=>{
        try{
            if(Wireframes !== undefined){
                setWireframes(
                <Grid container item xs={12} sx={{alignContent:'start'}}>
                    <Grid container item xs={12} sx={{pb:2}}>
                        <Box component='p' sx={{pr:colPad}}>
                        <Typography variant = 'H3'>
                            Wireframes
                        </Typography>
                        </Box>
                    </Grid>
                    {Wireframes.map(function(element,i){
                       return (
                        <Grid container item xs sx={{pr:colPad,justifyContent:'start'}}>
                        <img src={element} style={{maxWidth:'100%'}}/>
                        </Grid> 
                        )
                    })}
            </Grid>)
            }
            if(Type !== undefined){
                setType(
                <Grid container item xs={12} md={6} sx={{
                    display:'flex',
                    flexDirection:'column',
                    alignContent:'start'
                    }}>
                    <Box sx={{pb:2}}>
                        <Typography variant = 'H3'>
                            Typography
                        </Typography>
                    </Box>
                    <img src={Type} style={{maxWidth:'100%'}}/>
                </Grid>
            )}
            if(Button !== undefined){
                setButtons(
                    <Grid container item xs={12} md={3} sx={{
                            display:'flex',
                            flexDirection:'column',
                            alignContent:'start'
                            }}>
                            <Box sx={{pb:2}}>
                                <Typography variant = 'H3'>
                                    UI - Buttons
                                </Typography>

                            </Box>
                        <img src={Button} style={{maxWidth:'100%'}}/>

                    </Grid>
                )

            }
            if (Colours !== undefined){
                setColours(
                    <Grid container item xs={12} md={3} sx={{
                            display:'flex',
                            flexDirection:'column',
                            alignContent:'start'
                            }}>
                            <Box sx={{pb:2}}>
                                <Typography variant = 'H3'>
                                    Colours
                                </Typography>

                            </Box>
                        <img src={Colours} style={{maxWidth:'100%'}}/>
                    </Grid>
                )
            }
        }
        catch(e){}
      },[]);


    return (
        <>
        <Grid container item xs={12} 
          sx={{
            display:'flex',
            alignContent:'start',
            }}>
         
          <Grid ref={expandRef} container item xs={12} sx={expanded? caseStudyExpanded(expandedHeight):caseStudyExpand}>
            <Grid container item xs={12} md={6} sx={{pr:colPad, alignContent:'start',pb:2}}>
                <Box sx={{pr:colPad,pb:2}}>
                <Typography variant = 'H3'>
                    Overview
                </Typography>
                </Box>
                <Box sx={{pr:colPad}}>
                <Typography variant = 'Body1'>
                    {Overview}
                </Typography>
                </Box>
            </Grid> 
            <Grid container item  xs={12} md={6} sx={{alignContent:'start',pb:2}}>
                <Box sx={{pr:colPad,pb:2}}>
                <Typography variant = 'H3'>
                    Process
                </Typography>
                </Box>
                <Box >
                <Typography variant = 'Body1'>
                    {Proccess}
                </Typography>
                </Box>
                
            </Grid>
            {type}
            {colours}
            {buttons}
            {wireframes}

            <Grid container item xs={12} sx={{alignContent:'start'}}>
                <Grid container item xs={12}>
                    <Box component='p' sx={{pr:colPad, pb:2}}>
                    <Typography variant = 'H3'>
                        Final
                    </Typography>
                    </Box>
                </Grid>
                <Grid container item  xs={12} md={6}  sx={{pr:colPad, alignContent:'start',justifyContent:'center'}}>
                    {ImagesCol1.map(function(element,i){
                       return (
                       <Box sx={{width:'100%', pb:2,display:'flex', alignContent:'start',justifyContent:'center'}}>
                        <img src={element} style={{maxWidth:'100%'}}  key={i} />
                        </Box> )
                    })}
                </Grid>
                <Grid container item  xs={12} md={6} sx={{alignContent:'start',justifyContent:'center'}}>
                    {ImagesCol2.map(function(element,i){
                       return (
                        <Box sx={{width:'100%',pb:2,display:'flex',alignContent:'start',justifyContent:'center'}}>
                        <img src={element}style={{maxWidth:'100%'}} key={i} />
                        </Box>)
                    })}
                </Grid>
            </Grid>

          </Grid>

          <Grid container item xs={12} sx={{}}>
            <Typography variant = 'H1' sx={{color:'secondary.linkColor'}}>
              <Box component='span' onClick={()=>setExpanded(!expanded)} sx={menuSX(red)}>
                    {expanded?<>show less</>:<>show more</>}
              </Box>
              </Typography>
          </Grid>
        </Grid>
        </>
    )
}
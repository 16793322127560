import { Grid ,Box,Typography, ThemeProvider} from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import useWindowSize from "../../utils/useWindowResize";
import { Link } from "react-router-dom";
import { black, blue, red, theme, white } from "../../styles/theme";
import { caseStudyExpand, caseStudyExpanded, menuSX, menuSXBlue } from "../../styles/styles";
import { Title } from "../../components/utils/utils";
import { CaseStudy, caseStudy } from "../../components/caseStudy";


export default function CreativeCodingPage(props) {

  return (
      <>

    <ThemeProvider theme={theme}>
      <Grid container item sx={{
        display:'flex',
        bgcolor:'secondary.bg',
        transition:'all ease .1s',
        // height:'200vh',
        position:'relative',
        alignContent:'flex-start',
      }}>
        <Grid container item xs={12} 
          sx={{
            display:'flex',
            position:'sticky',
            top:0,
            mixBlendMode:'difference'
            }}>
            <Grid container item xs={12} md>
            <Box>
              <Box component='span' sx={menuSX('#000')}>
                <Title title ={<>George&nbsp;Junichi McKibbon&nbsp;Macrae</>} 
                      color1={white} colorHover={red} link={'/'}/>
              </Box>
            </Box>  
            </Grid>
            <Grid container item xs={12} md sx={{justifyContent:{xs:'start',md:'start'}}}>
              <Box>
              <Title title ={<>Work</>} color1={white} colorHover={red} link={'/work'}/>
              <Title title ={<>Info</>} color1={white} colorHover={red} link={'/info'}/>
              </Box>
            </Grid>
        </Grid>

        <Grid xs={0} md={1}/>
        <Grid xs={0} md={10}>
        
          <Box sx={{pt:5}}>
          <CaseStudy 
            Title={"CREATIVE CODING"} 
            Date={"2021-2022"} 
            Subtitle={"Graduation Capstone Project for Emily Carr University 2022"}
            Body={"Over the course of my Communication Design education at Emily Carr, I didn't feel like there was much focus on designing for digital spaces.  The graduation project gave me an opportunity to marry my skill with technology with my passion for design.  I took a deep dive into exploring how Creative Coding can be applied to graphic design while learning essential and practical skills for designing online spaces."}
            Tags={["Creative Coding", ,"Communication Design","UI/UX", "Research"]}
            Img={["/images/CreativeCoding/creativeCoding.gif","/images/CreativeCoding/creativeCoding2.gif"]}
            Link={["Creative Coding Site","https://creative-code-alpha.vercel.app/"]}
            />
        </Box>

        
        </Grid>
        <Grid xs={0} md={1}/>

      </Grid>
      </ThemeProvider>
    
    
      </>
  );
}

